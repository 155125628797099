import { Typography } from "@mui/material";
import "./projects.css";
import Media from "react-media";

function Projects() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div id="projects" className="projects-wrapper">
        <h1>Empowering youth to build a better future</h1>
        <br />
        <br />
        <br />

        <div className="projects-main">
          <Media queries={{ small: "(max-width:700px)" }}>
            {(size) =>
              size.small ? (
                <div className="cards" style={{ flexDirection: "column" }}>
                  <ProjectsCard
                    cardName="Sehat Khana"
                    cardDescription="Sehat Khana connects user to world class doctors throughout the world"
                    href="/sehatKhana"
                  />
                  <ProjectsCard
                    cardName="Karobaar Ghar"
                    cardDescription="Karobaar Ghar provides formal education to students who can't afford it or live in remote areas"
                    href="/karobaarGhar"
                  />
                  <ProjectsCard
                    cardName="Hunar Ghar"
                    cardDescription="Hunar Ghar helps local crafts men sell their crafts at market rate without involvement of 3rd party"
                    href="/hunarGhar"
                  />
                  <ProjectsCard
                    cardName="Taleem Ghar"
                    cardDescription="Taleem ghar is a mentorship program which is designed to create future leaders through informal education"
                    href="/taleemGhar"
                  />
                </div>
              ) : (
                <div className="cards">
                  <ProjectsCard
                    cardName="Sehat Khana"
                    cardDescription=" Sehat Khana connects user to world class doctors throughout the world"
                    href="/sehatKhana"
                  />
                  <ProjectsCard
                    cardName="Karobaar Ghar"
                    cardDescription="Karobaar Ghar provides formal education to students who can't afford it or live in remote areas"
                    href="/karobaarGhar"
                  />
                  <ProjectsCard
                    cardName="Hunar Ghar"
                    cardDescription="Hunar Ghar helps local crafts men sell their crafts at market rate without involvement of 3rd party"
                    href="/hunarGhar"
                  />
                  <ProjectsCard
                    cardName="Taleem Ghar"
                    cardDescription="Taleem ghar is a mentorship program which is designed to create future leaders through informal education"
                    href="/taleemGhar"
                  />
                </div>
              )
            }
          </Media>
        </div>
      </div>
    </div>
  );
}

function ProjectsCard({ cardName, cardDescription, href }) {
  return (
    <>
      <Media queries={{ small: "(max-width:700px)" }}>
        {(size) =>
          size.small ? (
            <div
              className="card"
              style={{
                flexDirection: "column",
                width: "80vw",
                flexWrap: "nowrap",
              }}
            >
              <Typography sx={{ fontSize: 12 }}>
                <h1 className="card-name">{cardName}</h1>
                <h3 className="card-description">{cardDescription}</h3>
                <a href={href} target={"_blank"} rel="noreferrer">
                  find out more →{" "}
                </a>
              </Typography>
            </div>
          ) : (
            <div
              className="card"
              style={{ flexDirection: "column", width: "22vw", height: "25vw" }}
            >
              <Typography sx={{ fontSize: 14 }}>
                <h1 className="card-name">{cardName}</h1>
                <h3 className="card-description">{cardDescription}</h3>
                <a href={href} target={"_blank"} rel="noreferrer">
                  find out more →{" "}
                </a>
              </Typography>
            </div>
          )
        }
      </Media>
    </>
  );
}

export default Projects;
