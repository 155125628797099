import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
// import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
// import Container from "@mui/material/Container";
// import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
// import MenuItem from "@mui/material/MenuItem";
// import AdbIcon from "@mui/icons-material/Adb";
import { useState } from "react";
import "./Navbar.css";
import LOGO from "../../assets/images/mrk_logo.png";
import {
  // createTheme,
  // responsiveFontSizes,
  Drawer,
  ListItemButton,
  ListItemText,
  CssBaseline,
  Divider,
  List,
  ListItem,
} from "@mui/material";
function Navbar() {
  // return (
  //   <header className="navbar-main">
  //     <div className="navbar-logo">
  //       <a href="#home">
  //         <img src={LOGO}></img>
  //       </a>
  //     </div>
  //     <div className="navbar-items">
  //       <ul>
  //         <li>
  //           <a href="#home"> Home</a>
  //         </li>

  // <div className="dropdown">
  //   <button className="dropbtn">Projects</button>
  //   <div className="dropdown-content">
  //     <a href="/sehatKhana" target={"_blank"}>
  //       Sehat Khana
  //     </a>
  //     <a href="/hunarGhar" target={"_blank"}>
  //       Hunar Ghar
  //     </a>
  //     <a href="/karobaarGhar" target={"_blank"}>
  //       Karobaar Ghar
  //     </a>
  //     <a href="/taleemGhar" target={"_blank"}>
  //       Taleem Ghar
  //     </a>
  //   </div>
  //         </div>
  //         <li>
  //           <a href="#mission"> Our Mission</a>
  //         </li>
  //         <li>
  //           <a href="#about"> About</a>
  //         </li>
  //         <li>
  //           <a href="#contact"> Contact</a>
  //         </li>
  //         <li>
  //           <a href="#login"> Login/Signup</a>
  //         </li>
  //       </ul>
  //     </div>
  //   </header>
  // );
  // return <ResponsiveAppBar />;
  return <DrawerAppBar />;
}
export default Navbar;

// let theme = createTheme();
// theme = responsiveFontSizes(theme);

// const pages = ["HOME", "PLATFORMS", "MISSION", "CONTACT", "BLOGS"];
// // const settings = ["Profile", "Account", "Dashboard", "Logout"];

// function ResponsiveAppBar() {
//   const [anchorElNav, setAnchorElNav] = useState(null);
//   // const [anchorElUser, setAnchorElUser] = useState(null);

//   const handleOpenNavMenu = (event) => {
//     setAnchorElNav(event.currentTarget);
//   };
//   // const handleOpenUserMenu = (event) => {
//   //   setAnchorElUser(event.currentTarget);
//   // };

//   const handleCloseNavMenu = () => {
//     setAnchorElNav(null);
//   };

//   // const handleCloseUserMenu = () => {
//   //   setAnchorElUser(null);
//   // };

//   return (
//     <AppBar position="sticky" style={{ background: "rgb(255, 238, 207)" }}>
//       <Container maxWidth="xl">
//         <Toolbar disableGutters>
//           <Box
//             component="img"
//             sx={{
//               height: 50,
//               maxHeight: { xs: 50, md: 100 },
//             }}
//             alt="MRKT LOGO"
//             src={LOGO}
//           />
//           <Tooltip title=" Muhammad Ramzan Kamboh Trust">
//             <Typography
//               theme={theme}
//               variant="h3"
//               noWrap
//               component="a"
//               href="/"
//               sx={{
//                 mr: 2,
//                 display: { xs: "none", md: "flex" },
//                 fontFamily: "Roboto",
//                 fontWeight: 500,
//                 letterSpacing: ".3rem",
//                 color: "inherit",
//                 textDecoration: "none",
//               }}
//               style={{ color: "black" }}
//             >
//               MRKT
//             </Typography>
//           </Tooltip>

//           <Box
//             sx={{
//               justifyContent: "flex-end",
//               flexGrow: 1,
//               display: { xs: "flex", md: "none" },
//             }}
//           >
//             <IconButton
//               size="large"
//               aria-label="account of current user"
//               aria-controls="menu-appbar"
//               aria-haspopup="true"
//               onClick={handleOpenNavMenu}
//               color="black"
//             >
//               <MenuIcon />
//             </IconButton>
//             <Menu
//               id="menu-appbar"
//               anchorEl={anchorElNav}
//               anchorOrigin={{
//                 vertical: "bottom",
//                 horizontal: "left",
//               }}
//               keepMounted
//               transformOrigin={{
//                 vertical: "top",
//                 horizontal: "left",
//               }}
//               open={Boolean(anchorElNav)}
//               onClose={handleCloseNavMenu}
//               sx={{
//                 display: { xs: "block", md: "none" },
//               }}
//             >
//               {pages.map((page) => (
//                 <MenuItem key={page} onClick={handleCloseNavMenu}>
//                   <Typography textAlign="center">{page}</Typography>
//                 </MenuItem>
//               ))}
//             </Menu>
//           </Box>
//         </Toolbar>
//       </Container>
//     </AppBar>
//   );
// }

const drawerWidth = 150;
const navItems = ["Home", "Mission", "Contact", "Blogs"];

function DrawerAppBar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Box
        component="img"
        sx={{
          mt: 2,
          height: 50,
          maxHeight: { xs: 50, md: 100 },
        }}
        alt="MRKT LOGO"
        src={LOGO}
      />
      <Typography variant="h6" sx={{ mb: 2 }}>
        MRKT
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton sx={{ textAlign: "center" }}>
              <ListItemText primary={item} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          component="nav"
          style={{ backgroundColor: "rgb(255, 238, 207)" }}
        >
          <Toolbar>
            <IconButton
              color="black"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>

            <Box
              sx={{ display: { xs: "none", sm: "block", md: "inline-block" } }}
            >
              <Tooltip title=" Muhammad Ramzan Kamboh Trust">
                <Typography
                  // theme={theme}
                  variant="h3"
                  noWrap
                  component="a"
                  href="/"
                  sx={{
                    mr: 2,
                    display: { xs: "none", md: "flex" },
                    fontFamily: "Roboto",
                    fontWeight: 500,
                    letterSpacing: ".3rem",
                    color: "inherit",
                    textDecoration: "none",
                  }}
                  style={{ color: "black" }}
                >
                  <Box
                    component="img"
                    sx={{
                      height: 50,
                      maxHeight: { xs: 50, md: 100 },
                      display: { xs: "none", md: "flex" },
                    }}
                    alt="MRKT LOGO"
                    src={LOGO}
                  />
                  MRKT
                </Typography>
              </Tooltip>
            </Box>

            <Box sx={{ flexGrow: 1 }} />

            <Box sx={{ display: { xs: "none", sm: "block" } }}>
              {navItems.map((item) => (
                <Button key={item} sx={{ color: "black" }}>
                  {item}
                </Button>
              ))}
            </Box>
            <Box>
              <Typography>
                <div className="dropdown">
                  <button className="dropbtn">Projects</button>
                  <div className="dropdown-content">
                    <a href="/sehatKhana" target={"_blank"}>
                      Sehat Khana
                    </a>
                    <a href="/hunarGhar" target={"_blank"}>
                      Hunar Ghar
                    </a>
                    <a href="/karobaarGhar" target={"_blank"}>
                      Karobaar Ghar
                    </a>
                    <a href="/taleemGhar" target={"_blank"}>
                      Taleem Ghar
                    </a>
                  </div>
                </div>
              </Typography>
            </Box>
          </Toolbar>
        </AppBar>
        <Box component="nav">
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
        </Box>
      </Box>
    </>
  );
}
