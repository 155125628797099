/* eslint-disable react/style-prop-object */
/* eslint-disable jsx-a11y/anchor-is-valid */
import "./footer.css";
// import { useState } from "react";
import Iframe from "react-iframe";
import FB from "../../assets/images/fb.png";
import INSTA from "../../assets/images/insta.png";
import { Typography } from "@mui/material";
import Media from "react-media";
import { hover } from "@testing-library/user-event/dist/hover";

function Footer() {
  return (
    <>
      <Media queries={{ small: "(max-width:700px)" }}>
        {(size) =>
          size.small ? (
            <footer id="contact">
              <Typography sx={{ fontSize: 15 }}>
                <div
                  className="footer-main"
                  style={{
                    display: "block",
                    textAlign: "center",
                    width: "fit-content",
                    margin: "auto",
                  }}
                >
                  <div className="office" style={{ width: "100vw" }}>
                    <Typography variant="h1" sx={{ fontSize: 15 }}>
                      {" "}
                      <h1>LHR </h1>
                      <a
                        href="https://www.google.com/maps?q=31.4827992,74.4464562&z=17&hl=en"
                        target={"__blank"}
                      >
                        {" "}
                        <p>
                          204-W Block, Street 17, <br /> Phase 8, DHA, <br />{" "}
                          Lahore.
                        </p>
                      </a>
                    </Typography>
                  </div>

                  {/* <div className="office-map">
                    <Iframe
                      src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3402.50503709207!2d74.44426751514968!3d31.482799181382067!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzHCsDI4JzU4LjEiTiA3NMKwMjYnNDcuMiJF!5e0!3m2!1sen!2s!4v1676904664302!5m2!1sen!2s"
                      width={screenWidth}
                      height={screenWidth / 2}
                      style="border:0;"
                      allowfullscreen=""
                      loading="lazy"
                    />
                  </div> */}

                  <div className="contact phone-no" style={{ width: "100vw" }}>
                    <Typography variant="h1" sx={{ fontSize: 15 }}>
                      <h2> Contact </h2>
                      <a href="tel:+923174887446">(+92) 317-488-7446</a> <br />
                      <a href="tel:+96555077206">(+96) 55-507-7206</a>
                    </Typography>
                  </div>

                  <div className="contact email" style={{ width: "100vw" }}>
                    <Typography variant="h1" sx={{ fontSize: 15 }}>
                      <h2> Email </h2>
                      <a
                        subject="Contact Request"
                        href="mailto:contact@mrktrust.com"
                        style={{ color: "white", textDecoration: "underline" }}
                      >
                        contact@mrktrust.com
                      </a>
                      <br />
                      <a
                        subject="Info Request"
                        href="mailto:info@mrktrust.com"
                        style={{ color: "white", textDecoration: "underline" }}
                      >
                        info@mrktrust.com
                      </a>
                    </Typography>
                  </div>

                  <div className="contact sm-links" style={{ width: "100vw" }}>
                    <Typography variant="h1" sx={{ fontSize: 15 }}>
                      <h2> Social Media </h2>
                      <a
                        href="https://www.facebook.com/mrkttrust/"
                        target={"__blank"}
                      >
                        <img src={FB} alt="fb_icon" />
                        Facebook,
                      </a>{" "}
                      <br />
                      <a href="https://www.instagram.com" target={"__blank"}>
                        <img src={INSTA} alt="insta_icon" /> Instagram
                      </a>
                    </Typography>
                  </div>

                  <div className=" footer-privacy">
                    <div
                      className="legals trademark"
                      style={{ width: "100vw" }}
                    >
                      <p>©MRK Trust, all rights reserved</p>
                    </div>

                    <div
                      className="legals kiazend"
                      style={{ width: "100vw", paddingBottom: "15px" }}
                    >
                      <p>
                        Built with love by{" "}
                        <a href="http://kiazend.com/" target="blank">
                          Kiazend
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </Typography>
            </footer>
          ) : (
            <footer id="contact">
              <Typography sx={{ fontSize: 22, pl: 2 }}>
                <div className="footer-main">
                  <div className="row-3">
                    <div className="office">
                      <h2>LHR </h2>
                      <a
                        href="https://www.google.com/maps?q=31.4827992,74.4464562&z=17&hl=en"
                        target={"__blank"}
                      >
                        {" "}
                        <p>
                          204-W Block, Street 17, <br /> Phase 8, DHA, <br />{" "}
                          Lahore.
                        </p>
                      </a>
                    </div>

                    <div className="office-map">
                      <Iframe
                        src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3402.50503709207!2d74.44426751514968!3d31.482799181382067!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzHCsDI4JzU4LjEiTiA3NMKwMjYnNDcuMiJF!5e0!3m2!1sen!2s!4v1676904664302!5m2!1sen!2s"
                        width={"900"}
                        height={"450"}
                        style="border:0;"
                        allowfullscreen=""
                        loading="lazy"
                      />
                    </div>
                  </div>

                  <div className="row-3">
                    <div className="contact phone-no">
                      <h2> Contact </h2>
                      <a href="tel:+923174887446">(+92) 317-488-7446</a> <br />
                      <a href="tel:+96555077206">(+96) 55-507-7206</a>
                    </div>

                    <div className="contact email">
                      <h2> Email </h2>
                      <a
                        subject="Contact Request"
                        href="mailto:contact@mrktrust.com"
                        style={{ color: "white", textDecoration: "underline" }}
                      >
                        contact@mrktrust.com
                      </a>
                      <br />
                      <a
                        subject="Info Request"
                        href="mailto:info@mrktrust.com"
                        style={{ color: "white", textDecoration: "underline" }}
                      >
                        info@mrktrust.com
                      </a>
                    </div>

                    <div className="contact sm-links">
                      <h2> Social Media </h2>
                      <a
                        href="https://www.facebook.com/mrkttrust/"
                        target={"__blank"}
                      >
                        <img src={FB} alt="fb_icon" />
                        Facebook,
                      </a>{" "}
                      <br />
                      <a href="https://www.instagram.com" target={"__blank"}>
                        <img src={INSTA} alt="insta_icon" /> Instagram
                      </a>
                    </div>
                  </div>

                  <div className="row-3 footer-privacy">
                    <div className="legals trademark">
                      <p>©MRK Trust, all rights reserved</p>
                    </div>

                    <div className="legals kiazend">
                      <p>
                        Built with love by{" "}
                        <a href="http://kiazend.com/" target="blank">
                          Kiazend
                        </a>
                      </p>
                    </div>
                    <div className="legals terms">
                      <p>
                        <a href="#">Privacy</a>, <a href="#">Terms</a>
                      </p>
                    </div>
                  </div>
                </div>
              </Typography>
            </footer>
          )
        }
      </Media>
    </>
  );
}
export default Footer;

function Mailto({ email, subject, body, ...props }) {
  return (
    <a href={`mailto:${email}?subject=${subject || ""}&body=${body || ""}`}>
      {props.children}
    </a>
  );
}
