import "./mission.css";
import MrShakeel from "../../assets/images/mr_shakeel.jpeg";
import MrSaleem from "../../assets/images/mr_saleem.jpeg";
import MrShabbir from "../../assets/images/mr_shabbir.jpeg";
import MrShafique from "../../assets/images/mr_shafique.png";
import MrsNisa from "../../assets/images/mrs_nisa.png";
import { people } from "../../data/members";
import MRSUZMA from "../../assets/images/dr_atia.jpeg";
import DRATIA from "../../assets/images/mrs_uzma.jpeg";
import ENGRAHMAD from "../../assets/images/engr_ahmad.jpg";
import MRFAISAL from "../../assets/images/mr_faisal.jpeg";
import DRNEHA from "../../assets/images/dr_neha_shakeel.jpeg";
import MRSDUA from "../../assets/images/mrs_dua_shafique.jpeg";
import DRFAHEEM from "../../assets/images/dr_faheemullah.jpeg";
import { Typography } from "@mui/material";
import Media from "react-media";

function Mission() {
  // console.log(
  //   window.matchMedia("(min-width:700px)").matches,
  //   window.matchMedia("(max-width:1700px)").matches
  // );
  return (
    <>
      <div id="mission" className="mission-main">
        <Typography sx={{ fontSize: 22 }}>
          <h1 style={{ padding: "15vh 0 0 0" }}> OUR MISSION</h1>
        </Typography>
        <Typography
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            paddingTop: "10vh",
          }}
        >
          <h1 style={{ width: "80%" }}>
            اباجی اکثرکہا کرتے تھے کہ "ایک اچھا انسان وہ ہے جسے دنیا سےرخصت ہونے
            کے بعد اسکے دنیا میں کیے گئے اچھے کاموں کی نسبت سے ھمیشہ یاد رکھا
            جاۓ۔"
          </h1>

          <p>&mdash; Narrated by Dr. M. Shafique Kamboh&mdash; </p>
        </Typography>

        <div className="mission-cards">
          <MemQuotes
            imgSrc={MrShakeel}
            memName="Engr. M. Shakeel Kamboh"
            memDesignation="Founder MRKT"
            memSaying="Tim cook once said “you want to be the pebbles in the pond that creates the ripples of change” MRKT is such an endeavor which is meant to bring change in society. Fundamentals of this change lie in providing quality health, alleviating poverty and rendering standard education to all. Inspiration for this noble work came from the benevolence and generosity of my father which he abundantly used to uplift the standards of deprived and marginalized members of the society. People trusted him and sought his help to solve their problems. Mentorship of my great humanly father gave me courage and confidence to embark upon this herculean task. MRKT is meant to carry forward his mission and to spread his message of social justice and equality."
          />

          <MemLeftQuote
            imgSrc={MrShafique}
            memName="Dr. M. Shafique"
            memDesignation="Patron-in-Chief MRKT "
            memSaying="Concept of MRKT is based upon ideology of social justice. As I grew up and became observant of the actions of my beloved father, I realized that he has an immense love and sympathy for the underprivileged and deserving members of the society. He was highly concerned about rampant poverty and illiteracy in our society. According to him these were the root causes of our ethical and moral decline. He struggled very hard to fulfill his ambition to eliminate poverty and illiteracy by facilitating provision of quality education and health to the deserving and unprivileged. MRKT came into being to bring his dream into reality and to fulfill his mission to build a healthy, literate and prosperous society where everyone can enjoy a life full of hope, zeal and fervor. 
            "
          />

          <MemQuotes
            imgSrc={MrsNisa}
            memName="Mrs. Waqar-un-Nisa"
            memDesignation="Director Operations MRKT"
            memSaying="MRKT is a message of love and justice. It is about helping the deserved and needy. It came into being inspired by the actions of my father, my hero who stood against malpractices and injustice in our society. He used to say “ if you have a dream go for it”. Through the platform of MRKT we plan to make our youth independent, skilled, confident and self-sufficient in every field of life. This could only be done by providing standard professional education, vocational training, and quality health and job opportunities to all the members of society."
          />

          <MemLeftQuote
            imgSrc={MrShabbir}
            memName="Mr. Shabbir"
            memDesignation="Trustee MRKT"
            memSaying="Parents are the dearest gift of God. For me my father is my ideal  whom I always tried to follow. One of his outstanding characters was his moral courage to fight against the evils of poverty, illiteracy and ignorance. I am proud to be part of MRKT which is founded in the name of my father to pay him tribute by following his ideology to bring equality and justice   to every human being through standard education, health and means of livelihood. 
            May God help us. Ameen"
          />

          <MemQuotes
            imgSrc={MrSaleem}
            memName="Mr. M Saleem"
            memDesignation="Trustee MRKT"
            memSaying="My Abba G was a dynamic and passionate person. He was a literary person and inclined towards Sufism. Luckily his job was his passion also as he was working in a reputed Literary institute (Sindhi Adabi Board). I remember the time when I said to my father, “I want to be an artist.” He encouraged me to follow my passion and arranged tutoring session with a famous local artist. He managed to published my paintings in local children’s magazines and Newsletters. His encouragement motivated me to polish my skills and creativity.
            MRK Trust will carry on his vision to grow skilled, enlightened and healthy community members who can follow their passions."
          />

          <div id="about" className="how-started">
            <Media queries={{ small: "(max-width:700px)" }}>
              {(size) =>
                size.small ? (
                  <Typography sx={{ fontSize: 15 }}>
                    <h1>Our Members</h1>

                    <p style={{ margin: "10px", justifyContent: "center" }}>
                      MRKT is a name of an ideology to help alleviate sufferings
                      from society through health and education. This priciple
                      has been the burning flame of inspiration since the
                      inception of this platform and over time many candidates,
                      from a broad horizon of backgrounds, have joined us. Some
                      of our current members include:
                    </p>
                  </Typography>
                ) : (
                  <Typography sx={{ fontSize: 20 }}>
                    <h1 style={{ marginTop: "100px" }}>Our Members</h1>

                    <p style={{ margin: "50px" }}>
                      MRKT is a name of an ideology to help alleviate sufferings
                      from society through health and education. This priciple
                      has been the burning flame of inspiration since the
                      inception of this platform and over time many candidates,
                      from a broad horizon of backgrounds, have joined us. Some
                      of our current members include:
                    </p>
                  </Typography>
                )
              }
            </Media>
            <div
              className="member-cards"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              {/* <CardData /> */}
              <FakeCards
                img={DRATIA}
                name={"Dr. Atiya Rizwan"}
                designation={"Motivational Speaker"}
                city={"Lahore"}
              />
              <FakeCards
                img={MRSUZMA}
                name={"Mrs. Uzma Mustafa"}
                designation={"Motivational Speaker"}
                city={"Lahore"}
              />
              <FakeCards
                img={ENGRAHMAD}
                name={"Engr. M. Ahmad"}
                designation={"Speaker & Tech. volunteer"}
                city={"Lahore"}
              />
              <FakeCards
                img={MRFAISAL}
                name={"Mr. Faisal"}
                designation={"Technical Volunteer"}
                city={"Lahore"}
              />
              <FakeCards
                img={DRFAHEEM}
                name={"Dr. Faheemullah"}
                designation={"Volunteer"}
                city={"Lahore"}
              />
              <FakeCards
                img={DRNEHA}
                name={"Dr. Neha Shakeel"}
                designation={"Volunteer"}
                city={"Lahore"}
              />
              <FakeCards
                img={MRSDUA}
                name={"Engr. Dua Shafique"}
                designation={"Volunteer"}
                city={"Canada"}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function MemQuotes({ memName, memDesignation, imgSrc, memSaying }) {
  return (
    <>
      <Media queries={{ small: "(max-width:700px)" }}>
        {(size) =>
          size.small ? (
            <div
              data-aos="zoom-out-down"
              className="mission-card  mission-card-black"
              style={{ width: "85vw" }}
            >
              <div
                className="mission-card-text black-card-text"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  className="mission-card-pic"
                  style={{
                    position: "relative",
                  }}
                >
                  <img
                    src={imgSrc}
                    style={{ width: "100px", height: "100px" }}
                    alt="Dr. M. Shakeel Kamboh"
                  />
                  <Typography>
                    <h4>{memName} </h4>
                    <p> {memDesignation}</p>
                  </Typography>
                </div>
                <Typography sx={{ fontSize: 15 }}>
                  <p>{memSaying}</p>
                </Typography>
              </div>
            </div>
          ) : (
            <div
              data-aos="zoom-out-down"
              className="mission-card  mission-card-black"
              style={{ width: "95vw" }}
            >
              <div
                className="mission-card-pic"
                style={{
                  position: "relative",
                  right: "50px",
                  paddingRight: "30px",
                }}
              >
                <img src={imgSrc} alt="Engr. M. Shakeel Kamboh" />
                <Typography>
                  <h4>{memName} </h4>
                  <p> {memDesignation}</p>
                </Typography>
              </div>

              <div className="mission-card-text black-card-text">
                <Typography sx={{ fontSize: 20 }}>
                  <p>{memSaying}</p>
                </Typography>
              </div>
            </div>
          )
        }
      </Media>
    </>
  );
}
function MemLeftQuote({ memName, memSaying, memDesignation, imgSrc }) {
  return (
    <Media queries={{ small: "(max-width:700px)" }}>
      {(size) =>
        size.small ? (
          <div
            data-aos="zoom-out-down"
            id="mission-card-white"
            className="mission-card"
          >
            <div className="mission-card-text">
              <div className="mission-card-pic">
                <img
                  src={imgSrc}
                  alt="member_pic"
                  style={{ width: "100px", height: "100px" }}
                />
                <Typography>
                  <h4>{memName} </h4>
                  <p> {memDesignation}</p>
                </Typography>
              </div>
              <Typography>
                <p>{memSaying}</p>
              </Typography>
            </div>
          </div>
        ) : (
          <div
            data-aos="zoom-out-down"
            id="mission-card-white"
            className="mission-card"
          >
            <div className="mission-card-text" style={{ paddingRight: "50px" }}>
              <Typography sx={{ fontSize: 20 }}>
                <p>{memSaying}</p>
              </Typography>
            </div>
            <div className="mission-card-pic">
              <img src={imgSrc} alt="member_pic" />
              <Typography>
                <h4>{memName} </h4>
                <p> {memDesignation}</p>
              </Typography>
            </div>
          </div>
        )
      }
    </Media>
  );
}

export default Mission;

function FakeCards({ img, name, designation, city }) {
  return (
    <>
      <Media queries={{ small: "(max-width:700px)" }}>
        {(size) =>
          size.small ? (
            <div className="member" style={{ marginTop: "50px" }}>
              <img src={img} alt={name} />
              <Typography>
                <p>
                  <b>{name}</b>
                </p>
                <p>
                  {designation}, {city}
                </p>
              </Typography>
            </div>
          ) : (
            <div className="member" style={{ marginTop: "50px" }}>
              <img src={img} alt={name} />
              <p>
                <b>{name}</b>
              </p>
              <p>
                {designation}, {city}
              </p>
            </div>
          )
        }
      </Media>
    </>
  );
}
export function CardData() {
  const listItems = people.map((person) => (
    <div className="member" key={person.id}>
      <img src={person.imgId} alt={person.name} />
      <Typography>
        <p>
          <b>{person.name}</b>
        </p>
        <p>
          {person.designation}, {person.city}
        </p>
      </Typography>
    </div>
  ));
  return listItems;
}

// let member = members.map((member)=>member.name);
// console.log(members);
