
import Navbar from "../components/navbar/Navbar"
import Home from "../components/home/Home"
import Projects from "../components/projects/projects"
import Mission from "../components/mission/mission"
import Footer from "../components/footer/footer"

export default function HomeMain(){
    return(
        <>
        <Navbar />
        <Home />
        <Projects />
        <Mission />
        <Footer />
        </>
    )
}