import { BrowserRouter, Route, Link, Routes } from "react-router-dom";
import "./App.css";
import HomeMain from "./routes/HomeMain";
import SehatKhana from "./routes/SehatKhana";
import KarobaarGhar from "./routes/KarobaarGhar";
import HunarGhar from "./routes/hunarGhar";
import TijaratGhar from "./routes/tijaratGhar";
import Working from "./routes/Working";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomeMain />} />

        <Route path="/sehatKhana" element={<Working />} />

        <Route path="/karobaarGhar" element={<Working />} />

        <Route path="/hunarGhar" element={<Working />} />

        <Route path="/taleemGhar" element={<Working />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
