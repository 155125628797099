import "./Home.css";
import HOMEIMG from "../../../src/assets/images/Group1.png";
import {
  Typography,
  // createTheme,
  // responsiveFontSizes,
  Card,
  CardContent,
} from "@mui/material";
import Media from "react-media";

function Home() {
  return (
    <>
      <div className="home-wrapper">
        <div id="home" className="home-main">
          <Media queries={{ small: "(max-width:700px)" }}>
            {(size) =>
              size.small ? (
                <Card
                  sx={{
                    width: "85%",
                    position: "absolute",
                    top: "15vh",
                    left: "3vw",
                    backgroundColor: "rgb(255, 213, 220)",
                    border: "5px solid black;",
                  }}
                >
                  <CardContent>
                    <Typography variant="h4" component="div">
                      Muhammad Ramzaan Kamboh Trust
                    </Typography>
                    <Typography variant="h6" color="text.secondary">
                      Serving humanity through{" "}
                      <span className="blue-text"> HEALTH </span>
                      and <span className="purple-text">EDUCATION</span>
                    </Typography>
                  </CardContent>
                </Card>
              ) : (
                <Card
                  sx={{
                    width: "35%",
                    position: "absolute",
                    top: "20vh",
                    left: "3vw",
                    backgroundColor: "rgb(255, 213, 220)",
                    border: "5px solid black;",
                  }}
                >
                  <CardContent>
                    <Typography variant="h4" component="div">
                      Muhammad Ramzaan Kamboh Trust
                    </Typography>
                    <Typography variant="h6" color="text.secondary">
                      Serving humanity through{" "}
                      <span className="blue-text"> HEALTH </span>
                      and <span className="purple-text">EDUCATION</span>
                    </Typography>
                  </CardContent>
                </Card>
              )
            }
          </Media>
          ;
          <img src={HOMEIMG} alt="Muhammad Ramzaan Kamboh Trust" />
        </div>
      </div>
    </>
  );
}

export default Home;
